import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { compose, mapProps } from '@shakacode/recompose';
import { useQuery } from '~/lazy_apollo/client';
import menuItemModifierGroupsQuery from '../../../../libs/gql/queries/menus/menuItemModifierGroupsQuery.gql';

import { withIntl } from '../../../../utils/withIntl';
import { withTheme } from '../../../../utils/withTheme';
import menuItemCartSelectedMenuItemQuery from '../../../../libs/gql/queries/menu_item_carts/menuItemCartSelectedMenuItemQuery.gql';
import updateMenuItemCartSelectedMenuItemMutation from '../../../../libs/gql/mutations/menu_item_carts/updateMenuItemCartSelectedMenuItemMutation.gql';
import { closeEditSelectedItemModal, closeMenuItemModal, openMenuItemModal } from '../../../../shared/DishActions';
import { handleNotFound } from '../MenuItemCartHelpers';
import { getParam } from '../../../../utils/urls';
import LoadedModifierGroupsProvider from '../LoadedModifierGroupsProvider';

import BasicModal from '../../../../admin/shared/BasicModal';
import Loading from '../../../../shared/Loading';
import Query from '../../../../shared/Query';
import MenuItemDetails from '../../MenuItemDetails';
import SelectedItemForm from '../SelectedItemForm';

const EditSelectedItemModal = ({ t, modernLayout }) => {
  const orderingEventId = useSelector(state => state.consumer.orderingEventId) || -1;
  const selectedMenuItemId = useSelector(state => state.dishes.selectedMenuItemId);
  const showSelectedItemModal = useSelector(state => state.dishes.showSelectedItemModal);
  const menuEnabled = useSelector(state => state.dishes.menuEnabled);
  const scheduledAt = useSelector(state => state.dishes.scheduledAt);
  const onEditComplete = useSelector(state => state.dishes.onSelectedMenuItemEditComplete);
  const dispatch = useDispatch();
  const { data: modGroupsData } = useQuery(
    menuItemModifierGroupsQuery,
    {
      skip: !selectedMenuItemId,
      variables: {
        menuItemId: selectedMenuItemId,
      },
    },
  );

  if (!selectedMenuItemId || !showSelectedItemModal) {
    return null;
  }

  const onCloseModal = () => {
    if (!modernLayout) {
      dispatch(closeEditSelectedItemModal());
    } else {
      dispatch(closeMenuItemModal());
      const menuSlug = getParam('menu');
      window.history.replaceState(null, null, menuSlug ? `#menu=${menuSlug}` : '');
      dispatch(closeEditSelectedItemModal());
    }
  };

  return (
    <BasicModal
      closeButton={!modernLayout}
      closeModal={() => onCloseModal()}
      fullScreen={modernLayout}
      fullScreenMobile={!modernLayout}
      paper={!modernLayout}
      PaperProps={{
        'aria-label': t('consumer.ordering.edit_order'),
      }}
      scroll={!modernLayout ? 'paper' : undefined}
      size={!modernLayout ? 'md' : undefined}
      show={showSelectedItemModal}
      transition={modernLayout ? 'left' : undefined}
    >
      <Query
        fetchPolicy="network-only"
        query={menuItemCartSelectedMenuItemQuery}
        variables={{ id: selectedMenuItemId, orderingEventAvailable: orderingEventId !== -1, orderingEventId }}
        onError={(err) => {
          onCloseModal();
          handleNotFound(err, dispatch);
        }}
      >
        {({ data, loading }) => {
          if (!modernLayout && (loading || !data || !data.menuItemCartSelectedMenuItem || !data.menuItemCartSelectedMenuItem.menuItem)) {
            return <Loading />;
          }
          const selectedMenuItem = data?.menuItemCartSelectedMenuItem;
          const menuItem = selectedMenuItem?.menuItem;

          if (!modernLayout && modGroupsData?.menuItem?.modifierGroups) {
            return (
              <LoadedModifierGroupsProvider initialModifierGroups={modGroupsData.menuItem.modifierGroups}>
                <SelectedItemForm
                  closeModal={onCloseModal}
                  menuItem={menuItem}
                  selectedMenuItem={selectedMenuItem}
                  selectedMenuItemMutation={
                    updateMenuItemCartSelectedMenuItemMutation
                  }
                  onSubmitComplete={onEditComplete}
                />
              </LoadedModifierGroupsProvider>
            );
          }

          if (!modernLayout) {
            return (
              <SelectedItemForm
                closeModal={onCloseModal}
                menuItem={menuItem}
                selectedMenuItem={selectedMenuItem}
                selectedMenuItemMutation={
                  updateMenuItemCartSelectedMenuItemMutation
                }
                onSubmitComplete={onEditComplete}
              />
            );
          }

          return (
            <MenuItemDetails
              closeMenuItemModal={onCloseModal}
              isModal
              loading={loading}
              menuEnabled={menuEnabled}
              menuItem={menuItem}
              selectedMenuItem={selectedMenuItem}
              menuItemId={menuItem?.id}
              openMenuItemModal={(nextMenuItemId, ...params) => dispatch(openMenuItemModal(nextMenuItemId, ...params))}
              onSubmitComplete={onEditComplete}
              scheduledAt={scheduledAt}
            />
          );
        }}
      </Query>
    </BasicModal>
  );
};

EditSelectedItemModal.propTypes = {
  t: PropTypes.func.isRequired,
};

export default compose(
  withIntl,
  withTheme,
  mapProps(props => ({
    ...props,
    modernLayout: props.theme.dishLayout === 'modern_dish_layout',
  })),
)(EditSelectedItemModal);
